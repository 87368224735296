.token-lockup-3 {
  align-items: center;
  display: flex;
  margin-left: 12px;
  min-width: 67px;
}

/* .logo {
  height: 24px;
  object-fit: cover;
  width: 24px;
} */

.hoge-4 {
  height: 16px;
  letter-spacing: 0;
  line-height: 16px;
  margin-bottom: 2.0px;
  margin-left: 6px;
  min-width: 37px;
  white-space: nowrap;
}

.token-lockup-3.token-lockup-4,
.token-lockup-3.token-lockup-7 {
  min-width: 69px;
}

.token-lockup-3.token-lockup-4 .hoge-4,
.token-lockup-3.token-lockup-7 .hoge-4 {
  min-width: 27px;
}

.token-lockup-3.token-lockup-5,
.token-lockup-3.token-lockup-8 {
  justify-content: flex-end;
  min-width: 69px;
}

