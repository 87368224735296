.carousel-indicators-3,
.carousel-indicators-1 {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  /* margin-top: 24px; */
  min-width: 108px;
}

.arrow-chevron-1 {
  height: 10px;
  margin-right: 20px;
  width: 6px;
}

.arrow-chevron-1,
.arrow-chevron-3 {
  height: 10px;
  width: 6px;
}

.dots-3,
.dots-1 {
  align-items: flex-start;
  display: flex;
  min-width: 48px;
}

.ellipse-3,
.ellipse-3-1 {
  background-color: var(--chateau-green);
  border-radius: 4px;
  height: 8px;
  width: 8px;
  margin-left: 6px;
  margin-right: 6px;
}

.ellipse,
.ellipse-1 {
  background-color: var(--pumice);
  border-radius: 4px;
  height: 8px;
  margin-left: 6px;
  margin-right: 6px;
  width: 8px;
}

.arrow-chevron-2,
.arrow-chevron-4 {
  height: 10px;
  margin-left: 18px;
  width: 6px;
}

