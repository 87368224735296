.row-order-d-1 {
  align-items: center;
  display: flex;
  height: 40px;
  margin-top: 20px;
  min-width: 496px;
  padding: 7px 8px;
  position: relative;
  grid-template-columns: 22% 22.5% 22.5% 21.5% 11.5%;
}

.buyOrder {
  height: 16px;
  letter-spacing: 0;
  line-height: 16px;
  margin-bottom: 2.0px;
  white-space: nowrap;
  width: 28px;
}

.sellOrder {
  color: #cf0000;
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-s);
  font-weight: 700;
  height: 16px;
  letter-spacing: 0;
  line-height: 16px;
  margin-bottom: 2.0px;
  white-space: nowrap;
  width: 28px;
}

.hoge-6 {
  /* height: 16px; */
  letter-spacing: 0;
  line-height: 16px;
  margin-bottom: 2.0px;
  /* margin-left: 38px; */
  min-width: 32px;
  white-space: nowrap;
}

.x0000093600 {
  align-self: flex-start;
  height: 24px;
  letter-spacing: 0;
  line-height: 24px;
  /* margin-left: 76px; */
  min-width: 104px;
  /* text-align: right; */
  white-space: nowrap;
}

.icon-cancel-x {
  height: 24px;
  margin-left: 32px;
  width: 24px;
}

.row-order-d-1.row-order-d-2 .hoge-6 {
  margin-left: 29px;
}

