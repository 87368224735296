.limitOrderBtn {
  align-items: flex-start;
  border-radius: 6px 0px 0px 6px;
  display: flex;
  height: 52px;
  min-width: 153px;
  padding: 17px 64.5px;
}

.activeBtn {
  background-color: var(--white);
}

.limitOrderBtnTxt {
  height: 16px;
  letter-spacing: 0;
  line-height: 16px;
  min-width: 24px;
  text-align: center;
  white-space: nowrap;
}
