.mobile-tabs {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  min-height: 28px;
  width: 335px;
  margin-top:33px;
}

.flex-row {
  align-items: flex-start;
  display: flex;
  margin-top: -1px;
  min-width: 335px;
}

.otc {
  height: 16px;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  white-space: nowrap;
  width: 167px;
}

.limit {
  height: 16px;
  letter-spacing: 0;
  line-height: 16px;
  margin-left: 1px;
  text-align: center;
  white-space: nowrap;
  width: 167px;
}

.rectangle-1 {
  background-color: var(--chateau-green);
  height: 3px;
  margin-right: 0.5px;
  margin-top: 9px;
  width: 168px;
}

.line-8 {
  height: 1px;
  width: 335px;
}

