.col-labels-1 {
  align-items: flex-start;
  background-color: var(--desert-storm);
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 48px;
  width: 496px;
}

.line-4-1 {
  height: 1px;
  width: 496px;
}

.labels-2 {
  align-items: flex-start;
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  min-width: 408px;
  padding: 0 8px;
}

.limit-2 {
  height: 16px;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  min-width: 28px;
  white-space: nowrap;
}

.amount-1 {
  height: 16px;
  letter-spacing: 0;
  line-height: 16px;
  margin-left: 76px;
  margin-top: -1px;
  min-width: 43px;
  white-space: nowrap;
}

.surname-4 {
  height: 16px;
  letter-spacing: 0;
  line-height: 16px;
  margin-left: 194px;
  margin-top: -1px;
  white-space: nowrap;
}

.line-3-1 {
  height: 1px;
  margin-top: 16px;
  width: 496px;
}

.col-labels-1.col-labels-2 {
  align-self: center;
  margin-top: 37px;
}

