@import url("https://fonts.googleapis.com/css?family=Roboto:400,700,500|Inter:500");

:root {
    --black: 
  rgba(0, 0, 0, 1);
    --mine-shaft: 
  rgba(51, 51, 51, 1);
    --chateau-green: 
  rgba(57, 181, 74, 1);
    --pumice: 
  rgba(201, 201, 201, 1);
    --desert-storm: 
  rgba(248, 248, 248, 1);
    --white-2: 
  rgba(255, 255, 255, 0.251);
    --white: 
  rgba(255, 255, 255, 1);
  
    --font-size-xs: 10px;
    --font-size-s: 12px;
    --font-size-m: 14px;
    --font-size-l: 16px;
    --font-size-xl: 20px;
    --font-size-xxl: 32px;
  
    --font-family-inter: "Inter";
    --font-family-roboto: "Roboto";
  }

  .roboto-normal-mine-shaft-12px {
    color: var(--mine-shaft);
    font-family: var(--font-family-roboto);
    font-size: var(--font-size-s);
    font-style: normal;
    font-weight: 400;
  }
  .roboto-normal-mine-shaft-16px {
    color: var(--mine-shaft);
    font-family: var(--font-family-roboto);
    font-size: var(--font-size-l);
    font-weight: 400;
    font-style: normal;
  }
  .roboto-medium-white-12px {
    color: var(--white);
    font-family: var(--font-family-roboto);
    font-size: var(--font-size-s);
    font-weight: 500;
    font-style: normal;
  }
  .roboto-medium-white-14px {
    color: var(--white);
    font-family: var(--font-family-roboto);
    font-size: var(--font-size-m);
    font-style: normal;
    font-weight: 500;
  }
  .roboto-normal-white-12px {
    color: var(--white);
    font-family: var(--font-family-roboto);
    font-size: var(--font-size-s);
    font-style: normal;
    font-weight: 400;
  }
  .roboto-normal-white-14px {
    color: var(--white);
    font-family: var(--font-family-roboto);
    font-size: var(--font-size-m);
    font-weight: 400;
    font-style: normal;
  }
  .border-1px-white {
    border-width: 1px;
    border-style: solid;
    border-color: var(--white);
  }
  .roboto-bold-white-32px {
    color: var(--white);
    font-family: var(--font-family-roboto);
    font-size: var(--font-size-xxl);
    font-weight: 700;
    font-style: normal;
  }
  .roboto-bold-white-10px {
    color: var(--white);
    font-family: var(--font-family-roboto);
    font-size: var(--font-size-s);
    font-weight: 700;
    font-style: normal;
  }
  .roboto-bold-white-12px {
    color: var(--white);
    font-family: var(--font-family-roboto);
    font-size: var(--font-size-s);
    font-weight: 700;
    font-style: normal;
  }
  .roboto-bold-white-14px {
    color: var(--white);
    font-family: var(--font-family-roboto);
    font-size: var(--font-size-m);
    font-weight: 700;
    font-style: normal;
  }
  .roboto-medium-white-10px {
    color: var(--white);
    font-family: var(--font-family-roboto);
    font-size: var(--font-size-xs);
    font-weight: 500;
    font-style: normal;
  }
  .roboto-bold-chateau-green-12px {
    color: var(--chateau-green);
    font-family: var(--font-family-roboto);
    font-size: var(--font-size-s);
    font-style: normal;
    font-weight: 700;
  }
  .roboto-bold-chateau-green-14px {
    color: var(--chateau-green);
    font-family: var(--font-family-roboto);
    font-size: var(--font-size-m);
    font-weight: 700;
    font-style: normal;
  }
  .roboto-bold-mine-shaft-12px {
    color: var(--mine-shaft);
    font-family: var(--font-family-roboto);
    font-size: var(--font-size-s);
    font-weight: 700;
    font-style: normal;
  }
  .roboto-bold-mine-shaft-16px {
    color: var(--mine-shaft);
    font-family: var(--font-family-roboto);
    font-size: var(--font-size-l);
    font-weight: 700;
    font-style: normal;
  }
  .roboto-bold-mine-shaft-14px {
    color: var(--mine-shaft);
    font-family: var(--font-family-roboto);
    font-size: var(--font-size-m);
    font-weight: 700;
    font-style: normal;
  }
  .roboto-bold-chateau-green-32px {
    color: var(--chateau-green);
    font-family: var(--font-family-roboto);
    font-size: var(--font-size-xxl);
    font-weight: 700;
    font-style: normal;
  }
  .roboto-bold-white-16px {
    color: var(--white);
    font-family: var(--font-family-roboto);
    font-size: var(--font-size-l);
    font-weight: 700;
    font-style: normal;
  }

.screen a {
  display: contents;
  text-decoration: none;
}

.container-center-horizontal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  pointer-events: none;
  width: 100%;
}

.container-center-horizontal > * {
  flex-shrink: 0;
  pointer-events: auto;
}

.valign-text-middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.otcswap-pro-d-default {
  align-items: center;
  background-color: 
#121827;
  display: flex;
  flex-direction: column;
  height: 1707px;
  padding: 60px 0;
  position: relative;
  width: 1280px;
}

.lorem-ipsum-trade-yo {
  color: var(--white);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-xl);
  font-weight: 400;
  letter-spacing: 0;
  line-height: 28px;
  margin-top: 55px;
  min-height: 28px;
  text-align: center;
  white-space: nowrap;
  width: 930px;
}

/* .dashboards {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 52px;
} */

.market-orders-panel {
  align-items: flex-start;
  display: flex;
  gap: 0;
  min-height: 642px;
  min-width: 930px;
}

.available-trades {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 20px 0px 0px 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  padding: 31px 0;
  width: 560px;
}

.title,
.your-open-orders-1 {
  letter-spacing: 0;
  line-height: 36px;
  margin-left: 32px;
  min-height: 36px;
  white-space: nowrap;
}

.tabs {
  align-items: flex-start;
  display: flex;
  gap: 0;
  margin-left: 32px;
  margin-top: 37px;
}

.activeTradeBtn {
  align-items: center;
  background-color: var(--chateau-green);
  border: 1px solid var(--chateau-green);
  display: flex;
  gap: 10px;
  justify-content: center;
  min-height: 52px;
  min-width: 169px;
  padding-bottom: 18px;
  padding-left: 52px;
  padding-right: 52px;
  padding-top: 18px;
}

.buy-hoge,
.sell-hoge,
.buy,
.sell {
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  white-space: nowrap;
}

.inactiveTradeBtn {
  align-items: center;
  border: 1px solid var(--chateau-green);
  display: flex;
  gap: 10px;
  justify-content: center;
  min-height: 52px;
  min-width: 169px;
  padding-bottom: 18px;
  padding-left: 52px;
  padding-right: 52px;
  padding-top: 18px;
}

#sell-token-btn {
  border-radius: 0px 6px 6px 0px;
}

#buy-token-btn {
  border-radius: 6px 0px 0px 6px;
}

.buy-module {
  align-items: center;
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;
}

.table {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: 373px;
  position: relative;
  width: 496px;
  margin-bottom:auto;
}

.col-labels {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--desert-storm);
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-height: 48px;
}

.line-4 {
  align-self: stretch;
  height: 1px;
  margin-top: -0.5px;
  object-fit: contain;
}

.labels {
  align-items: flex-start;
  display: inline-grid;
  grid-template-columns: 27% 48% 25%;
  min-width: 408px;
  padding: 0 8px;
}

.someone-wants {
  height: 20px;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -4.5px;
  margin-bottom: -4.5px;
  /* min-width: 52px; */
}

.has {
  height: 16px;
  letter-spacing: 0;
  line-height: 16px;
  /* margin-left: 52px; */
  margin-top: -1px;
  /* min-width: 22px; */
  white-space: nowrap;
}

.arrow-triangle {
  align-self: inherit;
  height: 15px;
  margin-left: 4px;
  width: 10px;
}

.surname {
  height: 16px;
  letter-spacing: 0;
  line-height: 16px;
  /* margin-left: 154px; */
  margin-top: -1px;
  white-space: nowrap;
  flex-direction: row;
}

.line {
  align-self: stretch;
  height: 1px;
  margin-bottom: -0.5px;
  object-fit: contain;
}

.line-1 {
  align-self: stretch;
  height: 1px;
  object-fit: contain;
}

.carousel-indicators {
  position: relative;
  align-items: center;
  display: flex;
  gap: 24px;
  justify-content: center;
  min-height: 10px;
  min-width: 108px;
}

.arrow-chevron {
  height: 10px;
  width: 6px;
}

.dots {
  align-items: center;
  display: flex;
  gap: 12px;
  justify-content: center;
  min-height: 8px;
  min-width: 48px;
}

.ellipse-3 {
  background-color: var(--chateau-green);
  border-radius: 4px;
  height: 8px;
  width: 8px;
}

.ellipse {
  background-color: var(--pumice);
  border-radius: 4px;
  height: 8px;
  width: 8px;
}

.confirm-trade {
  align-items: flex-start;
  background-color: var(--chateau-green);
  border-radius: 0px 20px 20px 0px;
  display: flex;
  flex-direction: column;
  gap: 36px;
  min-height: 642px;
  min-width: 370px;
  padding-bottom: 32px;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 32px;
}

.confirm-trade-1,
.place-limit-order-1 {
  letter-spacing: 0;
  line-height: 36px;
  margin-top: -1px;
  white-space: nowrap;
}

.body {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 32px;
  min-height: 506px;
  width: 306px;
}

.trade-detail {
  align-items: flex-start;
  align-self: stretch;
  border-radius: 12px;
  display: flex;
  gap: 60px;
  min-height: 112px;
  padding-bottom: 24px;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 24px;
  position: relative;
}

.for-a-partial-fill {
  align-self: stretch;
  height: 42px;
  letter-spacing: 0;
  line-height: 21px;
  width: 306px;
}

.fields-body {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 32px;
  min-height: 288px;
  min-width: 306px;
}

.field-labeled-disabled {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 4px;
  min-height: 72px;
  width: 306px;
}

.you-buy,
.you-spend,
.limit-price,
.quantity {
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  white-space: nowrap;
}

.field {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--white-2);
  border-radius: 6px;
  display: flex;
  height: 52px;
  justify-content: flex-end;
  min-width: 306px;
  padding: 13px 12px 13px 20px;
}

.text {
  height: 24px;
  letter-spacing: 0;
  line-height: 24px;
  min-width: 24px;
  /* opacity: 0.5; */
  white-space: nowrap;
}

.token-info {
  align-items: center;
  align-self: center;
  display: flex;
  gap: 12px;
  justify-content: flex-end;
  /* margin-left: 149px; */
  position: relative;
}

.name {
  letter-spacing: 0;
  line-height: 16px;
  text-decoration: underline;
  white-space: nowrap;
}

.field-1 {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--white-2);
  border-radius: 6px;
  display: flex;
  height: 52px;
  justify-content: flex-end;
  min-width: 306px;
  padding: 13px 12px 13px 20px;
}

.token-info-1 {
  align-items: center;
  align-self: center;
  display: flex;
  /* gap: 12px; */
  justify-content: flex-end;
  /* margin-left: 159px; */
  position: relative;
}

.cta-lockup {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 12px;
  min-height: 80px;
  min-width: 306px;
}

.button-2 {
  align-items: center;
  background-color: var(--white);
  border-radius: 6px;
  display: flex;
  gap: 10px;
  height: 52px;
  justify-content: center;
  padding-bottom: 14px;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 14px;
  width: 306px;
}

.accept-trade,
.place-buy-order {
  height: 16px;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  white-space: nowrap;
}

.or-select-a-differen {
  height: 16px;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  white-space: nowrap;
  width: 306px;
}

/* .limit-orders-panel {
  align-items: flex-start;
  display: flex;
  gap: 0;
  min-height: 565px;
  min-width: 930px;
} */

/* .your-open-orders {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 20px 0px 0px 20px;
  display: flex;
  flex-direction: column;
  height: 565px;
  overflow: hidden;
  padding: 31px 0;
  width: 560px;
} */

.table-1 {
  align-items: flex-start;
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 37px;
  width: 496px;
}

.labels-1 {
  align-items: flex-start;
  display: flex;
  justify-content: flex-end;
  min-width: 408px;
}

.limit {
  height: 16px;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  min-width: 28px;
  white-space: nowrap;
}

.amount {
  height: 16px;
  letter-spacing: 0;
  line-height: 16px;
  margin-left: 76px;
  margin-top: -1px;
  min-width: 43px;
  white-space: nowrap;
}

.surname-1 {
  height: 16px;
  letter-spacing: 0;
  line-height: 16px;
  margin-left: 194px;
  margin-top: -1px;
  white-space: nowrap;
}

/* .place-limit-order {
  align-items: flex-start;
  background-color: var(--chateau-green);
  border-radius: 0px 20px 20px 0px;
  display: flex;
  flex-direction: column;
  gap: 36px;
  min-height: 565px;
  min-width: 370px;
  padding-bottom: 32px;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 32px;
} */

.body-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-height: 429px;
  width: 306px;
}

/* .wallet-info {
  align-items: flex-start;
  display: flex;
  height: 16px;
  min-width: 306px;
} */

.token-bal {
  align-items: flex-start;
  display: flex;
  gap: 8px;
}

.text-1 {
  letter-spacing: 0;
  line-height: 16px;
  text-align: right;
  white-space: nowrap;
}

/* .hoge,
.place {
  letter-spacing: 0;
  line-height: 16px;
  white-space: nowrap;
} */

.eth-bal {
  align-items: flex-start;
  display: flex;
  gap: 8px;
  margin-left: 219px;
}

/* .tabs-1 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  gap: 0;
  min-height: 52px;
} */

.button-3 {
  align-items: center;
  background-color: var(--white);
  border-radius: 6px 0px 0px 6px;
  display: flex;
  flex: 1;
  gap: 10px;
  justify-content: center;
  min-height: 52px;
  padding-bottom: 18px;
  padding-left: 52px;
  padding-right: 52px;
  padding-top: 18px;
}

.button-4 {
  align-items: center;
  border-radius: 0px 6px 6px 0px;
  display: flex;
  flex: 1;
  gap: 10px;
  justify-content: center;
  min-height: 52px;
  padding-bottom: 18px;
  padding-left: 52px;
  padding-right: 52px;
  padding-top: 18px;
}

.buy-module-1 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 32px;
  min-height: 313px;
}

.field-labeled-default {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 4px;
  min-height: 72px;
}

.price {
  height: 16px;
  letter-spacing: 0;
  line-height: 16px;
  margin-left: 8px;
  margin-top: -1px;
  white-space: nowrap;
}

.surname-2 {
  align-self: center;
  height: 16px;
  letter-spacing: 0;
  line-height: 16px;
  margin-bottom: 2px;
  margin-left: 149px;
  min-width: 83px;
  white-space: nowrap;
}

.text-7 {
  height: 24px;
  letter-spacing: 0;
  line-height: 24px;
  min-width: 24px;
  white-space: nowrap;
}

.token-info-2 {
  align-items: center;
  align-self: center;
  display: flex;
  gap: 12px;
  justify-content: flex-end;
  margin-left: 147px;
  position: relative;
}

.totals {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  height: 21px;
  min-width: 306px;
}

.total-cost {
  color: var(--white);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-m);
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  margin-top: -1px;
  min-height: 21px;
  min-width: 66px;
  white-space: nowrap;
}

.x00-eth {
  letter-spacing: 0;
  line-height: 21px;
  margin-left: 189px;
  margin-top: -1px;
  min-height: 21px;
  min-width: 51px;
  text-align: right;
  white-space: nowrap;
}

.button-5 {
  align-items: center;
  align-self: stretch;
  background-color: var(--white);
  border-radius: 6px;
  display: flex;
  gap: 10px;
  height: 52px;
  justify-content: center;
  padding-bottom: 14px;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 14px;
}

.nav {
  align-items: flex-start;
  display: flex;
  height: 72px;
  width: 100%;
  max-width: 1120px;
}

.logo-otc-swap-pro-ko {
  height: 49px;
  margin-top: 0;
  width: 255px;
}

.wallet-info-1 {
  align-self: center;
  border-radius: 20px;
  height: 12px;
  margin-bottom: 28px;
  margin-left: auto;
  /* width: 248px; */
  flex-grow: 1;
}

.button-6 {
  align-items: center;
  background-color: var(--white);
  border-radius: 4px;
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-left: 52px;
  padding-bottom: 14px;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 14px;
}

.connect-wallet {
  color: var(--mine-shaft);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-s);
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  white-space: nowrap;
}

.market-price-display {
  align-items: center;
  display: flex;
  gap: 12px;
  justify-content: center;
  margin-top: 33px;
}

.current-market-prices,
.price-1,
.x00000678 {
  letter-spacing: 0;
  line-height: 16px;
  white-space: nowrap;
}

.field-2 {
  align-items: center;
  background-color: var(--white-2);
  border-radius: 6px;
  display: flex;
  gap: 8px;
  justify-content: center;
  min-height: 40px;
  min-width: 107px;
  padding-bottom: 12px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 12px;
}

.place-1 {
  color: var(--chateau-green);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-s);
  font-weight: 700;
  letter-spacing: 0;
  line-height: 16px;
  white-space: nowrap;
}

.field-3 {
  align-items: center;
  background-color: var(--white-2);
  border-radius: 6px;
  display: flex;
  height: 40px;
  margin-left: 12px;
  min-width: 163px;
  padding: 0 12px;
}

.frame-12 {
  align-items: flex-start;
  display: flex;
  min-width: 105px;
}

.line-5 {
  height: 36px;
  margin-left: 11px;
  width: 1px;
}

.arrow-chevron-2 {
  height: 10px;
  margin-left: 24px;
  width: 6px;
}

.row-trade-d-1 {
  align-items: center;
  align-self: stretch;
  display: flex;
  height: 40px;
  min-width: 496px;
  position: relative;
  grid-template-columns: 22.5% 38.5% 23% 16%;
}

.pricePerToken {
  height: 24px;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 2px;
  /* margin-left: 69px; */
  min-width: 104px;
  /* text-align: right; */
  white-space: nowrap;
}

.row-trade-d-1.row-trade-d-3 .pricePerToken {
  margin-left: 60px;
}

.row-trade-d-1.row-trade-d .pricePerToken {
  margin-left: 51px;
}

.token-lockup {
  align-items: center;
  display: flex;
  gap: 6px;
  width: 72px;
}

.logo-eth {
  height: 24px;
  object-fit: cover;
  width: 24px;
}

.place-2 {
  letter-spacing: 0;
  line-height: 16px;
  white-space: nowrap;
}

.token-lockup-1 {
  align-items: center;
  display: flex;
  gap: 6px;
  /* margin-left: 40px; */
}

.logo-hoge {
  height: 24px;
  object-fit: cover;
  width: 24px;
}

.x8800000 {
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
}

.button-sm {
  align-items: center;
  background-color: var(--chateau-green);
  border-radius: 6px;
  display: flex;
  gap: 0;
  justify-content: center;
  /* margin-left: 40px; */
  padding-bottom: 12px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 12px;
}

.place-3 {
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  white-space: nowrap;
}

.detail-col {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 4px;
  min-height: 64px;
  width: 99px;
}

.someone-wants-1,
.price-2 {
  align-self: stretch;
  height: 16px;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  white-space: nowrap;
  width: 99px;
}

.text-2 {
  align-self: stretch;
  height: 24px;
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
  width: 99px;
}

.place-4,
.per-token {
  align-self: stretch;
  height: 16px;
  letter-spacing: 0;
  line-height: 16px;
  white-space: nowrap;
  width: 99px;
}

.token-lockup-2 {
  align-items: center;
  display: flex;
  gap: 6px;
  min-height: 24px;
  width: 67px;
  justify-content: flex-end;
}

.logo {
  height: 24px;
  object-fit: cover;
  width: 24px;
}

.hoge-1 {
  letter-spacing: 0;
  line-height: 16px;
  white-space: nowrap;
}

.token-lockup-2.token-lockup-3 {
  width: 69px;
}

.token-lockup-2.token-lockup-4 {
  justify-content: flex-end;
  width: 69px;
}

.hoge-2 {
  color: #ff0000;
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-s);
  font-weight: 700;
  height: 16px;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  min-width: 32px;
  white-space: nowrap;
}

.rotation {
  width: 20px;
  height: 20px;
  margin:5px auto;
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.fields {
  display: flex;
}
.wallet-connected {
  display: flex;
  gap: '8px';
  font-weight: '400';
}
.mobile-tabs {
  display:none;
}
.arrow-dropdown {
  display:none;
}
.show-at-symbol {
  display: none;
}
@media only screen and (max-width: 768px) {
  .body {
    align-content: center;
    width: 295px
  }
  .container-center-horizontal {
    display:contents;
    width:335px;
  }
  .otcswap-pro-d-default {
    width:335px;
  }
  .wallet-info-1 {
    display: none;
  }
  .lorem-ipsum-trade-yo {
    white-space: initial;
    width: 335px;
    min-height: fit-content;
  }
  .nav {
    display: contents;
  }
  .market-price-display {
    flex-direction: column;
    width: 244px;
  }
  .logo-otc-swap-pro-ko {
    margin-bottom: 32px;
  }
  .button-6 {
    margin-left: unset;
    width:335px;
  }
  .wallet-connected {
    display:none;
  }
  .dashboards {
    width:335px;
  }
  .market-orders-panel {
    width:335px;
    min-width: fit-content;
    flex-direction: column;
    align-items:flex-start;
  }
  .available-trades {
    width:335px;
    border-radius: 20px 20px 0px 0px;
    gap:24px;
  }
  .title {
    margin-left: unset;
    align-self: center;
    width: 295px;
  }
  .tabs {
    margin-left:unset;
    margin-top: unset;
    align-self: center;
    min-width: unset;
  }
  .activeTradeBtn {
    width: 147.5px;
    min-width: unset;
  }
  .inactiveTradeBtn {
    width: 147.5px;
    min-width: unset;
  }
  .buy-module {
    width: 295px;
    margin-top: unset;
  }
  .table {
    width: 295px;
    gap: 16px;
  }
  .surname {
    width: fit-content;
  }
  .row-trade-d-1 {
    width: 295px;
    height: 73px;
    min-width: unset;
    /* padding: 0 8px; */
    grid-template-columns: 24.5% 42% 33.5%;
    row-gap: 8px;
  }
  .place-2, .x8800000, .pricePerToken {
    font-size: 14px;

  }
  .place-2 {
    padding-left: 8px;
  }
  .button-sm {
    width:295px;
  }
  .col-labels {
    width: 295px;
  }
  .labels {
    min-width:unset;
    width: 295px;
    grid-template-columns: 24% 45.5% 30.5%;
  }
  .confirm-trade {
    width: 335px;
    min-width: unset;
    padding-left: unset;
    padding-right: unset;
    padding-top: unset;
    padding: 32px 20px 32px;
    border-radius: 0px 0px 20px 20px;
    position:relative;
  }
  .for-a-partial-fill {
    width: 298px;
  }
  .fields-body {
    width: 298px;
    min-width: unset;
  }
  .field-labeled-disabled {
    width: 298px;
  }
  .field {
    min-width: 298px;
  }
  .field-1 {
    min-width: 298px;
  }
  .cta-lockup {
    min-width: 298px;
  }
  .button-2 {
    width: 298px;
  }
  .or-select-a-differen {
    width: 298px;
  }
  .arrow-dropdown {
    position: absolute;
    display: block;
    top: 0;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
  }
  .logo-eth {
    display:none;
  }
  .mobile-tabs {
    display:block;
  }
  .inactiveMobileTab {
    display:none;
  }
  .limit-orders-panel {
    width: 335px;
    min-width:fit-content;
    flex-direction: column-reverse;
    align-items:flex-start;
    margin-top:unset;
  }
  .your-open-orders {
    width: 335px;
    border-radius: 0px 0px 20px 20px;
  }
  .place-limit-order {
    width: 335px;
    border-radius: 20px 20px 0px 0px;
  }
  .your-open-orders {
    width: 335px;
    height: 400px;
    gap: 24px;
  }
  .orders-module {
    width: 335px;
    height: 284px;
    margin-top: unset;
    min-height: unset;
  }
  .table-2 {
    width: 295px;
    height: fit-content;
    min-height: unset;
    margin-bottom: unset;
  }
  .line-7 {
    margin-top: unset;
  }
  .col-labels-1 {
    width: 295px;
    height: 40px;
    min-height: unset;
  }
  .labels-2 {
    min-width: unset;
    margin-top: 12px;
    margin-bottom: -4px;
  }
  .amount-1 {
    margin-left: 30px;
  }
  .surname-4 {
    display: none;
  }
  .row-order-d-1 {
    grid-template-columns: 21% 47% 32%;
    min-width: unset;
    height: 66px;
    /* margin-bottom: -9px; */
    margin-top: unset;
  }
  .sellOrder, .buyOrder, .hoge-6 {
    margin: 15px 0px -15px 0px;
  }
  .x-order {
    width: 295px;
    align-self: center;
    margin-left: unset;
  }
  .show-at-symbol {
    display: block;
  }
  .x8800000-1, .show-at-symbol, .x0000093600 {
    font-size: 14px;
  }
  .pad-if-mobile {
    margin-left: 58px;
    display: flex;
  }
  .icon-cancel-x {
    position:absolute;
    margin-left: auto;
    margin-right: auto;
    right: 8px;
  }
}