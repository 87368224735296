.sell-module-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  min-height: 313px;
  width: 306px;
}

.field-labeled-default-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 72px;
  width: 306px;
}

.limit-price,
.quantity {
  height: 16px;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  white-space: nowrap;
}

.field-7 {
  align-items: flex-start;
  background-color: var(--white-2);
  border-radius: 6px;
  display: flex;
  height: 52px;
  justify-content: flex-end;
  margin-top: 5px;
  min-width: 306px;
  max-width: 306px;
  padding: 13px 12px 13px 20px;
}

.price-6 {
  height: 24px;
  letter-spacing: 0;
  line-height: 24px;
  min-width: 106px;
  white-space: nowrap;
}

.surname-3 {
  align-self: center;
  height: 16px;
  letter-spacing: 0;
  line-height: 16px;
  margin-bottom: 2.0px;
  margin-left: auto;
  min-width: 83px;
  white-space: nowrap;
}

.field-labeled-default-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  min-height: 72px;
  width: 306px;
}

.text-7 {
  height: 24px;
  letter-spacing: 0;
  line-height: 24px;
  min-width: 82px;
  white-space: nowrap;
}

.token-info-3 {
  align-items: center;
  align-self: center;
  display: flex;
  height: 24px;
  margin-left: auto;
  min-width: 103px;
  position: relative;
}

.name-2 {
  height: 16px;
  letter-spacing: 0;
  line-height: 16px;
  margin-bottom: 2.0px;
  min-width: 22px;
  text-decoration: underline;
  white-space: nowrap;
}

.totals {
  align-items: flex-start;
  display: flex;
  height: 21px;
  margin-top: 32px;
  min-width: 306px;
}

.total-earnings {
  letter-spacing: 0;
  line-height: 21px;
  margin-top: -1px;
  min-height: 21px;
  min-width: 93px;
  white-space: nowrap;
}

.x04-eth {
  letter-spacing: 0;
  line-height: 21px;
  margin-left: auto;
  margin-top: -1px;
  min-height: 21px;
  min-width: 51px;
  text-align: right;
  white-space: nowrap;
}

.button-11 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 4px;
  display: flex;
  height: 52px;
  margin-top: 32px;
  min-width: 306px;
  padding: 17px 104px;
}

.place-sell-order {
  height: 16px;
  letter-spacing: 0;
  line-height: 16px;
  min-width: 98px;
  text-align: center;
  white-space: nowrap;
}

.sell-module-2.buy-module .price-6 {
  min-width: 42px;
}

.sell-module-2.buy-module .surname-3 {
  margin-left: 149px;
}

.sell-module-2.buy-module .text-7 {
  min-width: 24px;
}

.sell-module-2.buy-module .token-info-2 {
  margin-left: 147px;
}

.sell-module-2.buy-module .total-earnings {
  min-width: 66px;
}

.sell-module-2.buy-module {
  margin-left: 189px;
}

.sell-module-2.buy-module .button-11 {
  border-radius: 6px;
  padding: 17px 103.5px;
}

.sell-module-2.buy-module .place-sell-order {
  min-width: 99px;
}

