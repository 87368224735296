
.dashboards {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-top: 52px;
  min-height: 1247px;
  width: 930px;
}

.tabs {
  align-items: flex-start;
  display: flex;
  height: 52px;
  margin-left: 19px;
  margin-top: 37px;
  min-width: 338px;
  position: relative;
}

.sell-module {
  align-items: center;
  align-self: flex-end;
  display: flex;
  height: 323px;
  margin-top: 23px;
  min-width: 515px;
}

.sell-module-1 {
  align-items: center;
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  margin-bottom: -80px;
  min-height: 402px;
  position: relative;
  width: 496px;
}

.table-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 373px;
  position: relative;
  width: 496px;
}

.token-qty {
  height: 16px;
  letter-spacing: 0;
  line-height: 16px;
  margin-left: 52px;
  margin-top: -1px;
  min-width: 55px;
  white-space: nowrap;
}

/* .surname {
  height: 16px;
  letter-spacing: 0;
  line-height: 16px;
  margin-left: 135px;
  margin-top: -1px;
  min-width: 82px;
  white-space: nowrap;
} */

/* .arrow-triangle {
  align-self: center;
  height: 6px;
  margin-left: 4px;
  width: 10px;
} */

.line-3 {
  height: 1px;
  margin-top: 16px;
  width: 496px;
}

.line-7 {
  height: 1px;
  margin-top: 20px;
  width: 496px;
}

.arrow-triangle-1 {
  height: 10px;
  margin-left: 13px;
  margin-top: 15.0px;
  width: 6px;
}

.limit-orders-panel {
  align-items: flex-start;
  align-self: center;
  display: flex;
  margin-top: 40px;
  min-width: 930px;
  margin-top: 37px;
  min-height: 429px;
  position: relative;
}

.your-open-orders {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 20px 0px 0px 20px;
  display: flex;
  flex-direction: column;
  height: 565px;
  overflow: hidden;
  padding: 31px 0;
  width: 560px;
}

.x-order {
  letter-spacing: 0;
  line-height: 36px;
  margin-left: 32px;
  min-height: 36px;
  white-space: nowrap;
}

.orders-module {
  align-items: center;
  align-self: center;
  display: flex;
  flex-direction: column;
  margin-top: 37px;
  min-height: 402px;
  position: relative;
  width: 496px;
}

.place-limit-order {
  align-items: flex-start;
  background-color: var(--chateau-green);
  border-radius: 0px 20px 20px 0px;
  display: flex;
  flex-direction: column;
  min-height: 565px;
  padding: 31px 0;
  width: 370px;
}

.orderBody {
  align-items: flex-start;
  align-self: center;
  display: flex;
  flex-direction: column;
  margin-top: 37px;
  min-height: 429px;
  position: relative;
  width: 306px;
}

.wallet-info {
  align-items: flex-start;
  border-radius: 12px;
  display: flex;
  min-width: 306px;
}

.frame-18 {
  align-items: flex-start;
  display: flex;
  min-width: 149px;
}

.text-5 {
  height: 16px;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  min-width: 109px;
  text-align: right;
  white-space: nowrap;
}

.hoge,
.place {
  height: 16px;
  letter-spacing: 0;
  line-height: 16px;
  margin-left: 8px;
  margin-top: -1px;
  white-space: nowrap;
}

.frame-19 {
  align-items: flex-start;
  display: flex;
  margin-left: auto;
  min-width: 83px;
}

.text-6 {
  height: 16px;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  min-width: 52px;
  text-align: right;
  white-space: nowrap;
}

.tabs-1 {
  align-items: flex-start;
  display: flex;
  height: 52px;
  margin-top: 24px;
  min-width: 306px;
  position: relative;
}

