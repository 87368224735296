@tailwind base;
@tailwind components;
@tailwind utilities;

.inverted {
  filter: invert(1);
}

body,
html {
  height: 100%;
}

#root {
  height: 100%;
  display: flex;
  justify-content: center;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.bg-gray-900 {
  background-color: rgb(17 24 39);
}